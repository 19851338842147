import React, { useRef } from 'react';
import QRCode from "react-qr-code";
import Logo from "../../../assets/images/logo/mag.svg";
import stamp from "../../../assets/images/partner/stamp.svg";
import html2canvas from "html2canvas";
import moment from "moment";
const BankInvoice = () => {


    const HostUrl = window.location.origin + `/partner/bank-invoice`;

    const InvoiceRef = useRef();



    const handleCapture = () => {
        if (InvoiceRef.current !== null) {
            html2canvas(InvoiceRef.current).then((canvas) => {
                const imgData = canvas.toDataURL("image/jpeg");

                // Create a link element
                const link = document.createElement("a");
                link.href = imgData;
                link.download = "Bank Info Invoice.jpg";

                // Append the link to the body and trigger the download
                document.body.appendChild(link);
                link.click();

                // Remove the link from the body
                document.body.removeChild(link);
            });
        }
    };


    return (
        <>
            <div className="invoice-parent-page">
                <div className="invoice-page" ref={InvoiceRef}>
                    <div className="subpage">
                        <div className="invoice-top">
                            <img src={Logo} />
                            <div className="title">Bank Info Invoice</div>
                            <span>{moment().format('DD/MM/YYYY - HH:mma')}</span>
                            <div className="qr-code-wrap">
                                <QRCode
                                    size={256}
                                    value={HostUrl}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                        </div>
                        <div className="invoice-content">
                            <ul>
                                <li>
                                    <label htmlFor="">Bank Name</label>
                                    <span>Habib Metro</span>
                                </li>
                                <li>
                                    <label htmlFor="">Account Title</label>
                                    <span>MAG ORGANIC MEATS</span>
                                </li>
                                <li>
                                    <label htmlFor="">Account Number</label>
                                    <span>6027320311714132207</span>
                                </li>
                                <li className='m-0' style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.25)', padding: '5px 20px' }}>
                                    <label htmlFor="">IBAN</label>
                                    <span>PK98MPBL0273027140132207</span>
                                </li>

                            </ul>
                            <div className="ticket-instruction" style={{ padding: '5px 20px' }}>
                                <h5>Instructions</h5>
                                <ol>
                                    <li>Deposit funds in Bank.</li>
                                    <li>Take a clear and legible photo or scan of the receipt.</li>
                                    <li>Make sure all relevant information is visible, including the date, time, and amount of the of the deposit, as well as any bank or account information.</li>
                                    <li>Click on the upload button and select the file containing the receipt from your device</li>
                                    <li>Wait for the upload to complete, and verify that the file has been successfully uploaded</li>
                                    <li>Submit the uploaded receipt and wait for confirmation that it has been received and processed.</li>
                                </ol>
                                <h5>Note</h5>
                                <ol>
                                    <li>
                                        For international payments, use our crypto payment methods instead of Bank Transfer.
                                    </li>
                                </ol>
                            </div>
                            <ul>
                                <li>
                                    <img src={stamp} alt="mag_stamp" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="invoice-footer">
                    <button className='button button-bs w-100' onClick={handleCapture}>
                        Save to Gallery
                    </button>
                </div>
            </div>
        </>
    )
}

export default BankInvoice